import React, { useState } from "react";
import styles from "./InputForm.module.css";

function Randomizer() {
  //future: read from config file
  const seed = Math.random() * 100;
  if (seed <= 10) {
    return "Dumb";
  } else if (seed <= 20) {
    return "Almost smart";
  } else if (seed <= 25) {
    return "Smart?";
  }
  return "Average";
}

function InputForm(props) {
  const [enteredName, setEnteredName] = useState("");
  const [enteredAge, setEnteredAge] = useState("");

  function submitHandler(event) {
    event.preventDefault();
    const userData = {
      name: enteredName,
      age: +enteredAge,
      status: Randomizer(),
    };
    setEnteredName("");
    setEnteredAge("");
    props.onSubmitForm(userData);
  }

  function nameChangeHandler(event) {
    setEnteredName(event.target.value);
  }
  function ageChangeHandler(event) {
    setEnteredAge(event.target.value);
  }

  return (
    <form className={styles["input-form"]} onSubmit={submitHandler}>
      <label>Enter Name</label>
      <input
        type="text"
        value={enteredName}
        onChange={nameChangeHandler}
      ></input>
      <label>Enter Age</label>
      <input value={enteredAge} onChange={ageChangeHandler}></input>

      <button type="submit">Find out</button>
    </form>
  );
}

export default InputForm;
