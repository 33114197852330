import React, {useState} from "react";
import "./App.css";
import InputFormCheck from "./components/InputForm/InputFormCheck";

import UserListDisplay from "./components/UserList/UserListDisplay";

function App() {
  const DEFAULTLIST = [
    { name: "Justin", 
    age: 19, 
    status: "Pretty dumb" },
    { name: "Chris",
    age:19,
    status: "Quite dumb" },
    { name: "Nicky",
    age: 19,
    status: "Surprisingly, intelligent"}
  ];
  
  const [userList, setUserList] = useState(DEFAULTLIST);
  function SubmitFormHandler(userData){
    setUserList(
      (prevState) => {return [
        userData,
        ...prevState
      ]}
    );
  }
  function BadInputHandler(name) {

  }

  return (
    <div className="MyFont">
      <h1 className="App__h1">Persons Tracker</h1>
      <InputFormCheck users={userList} onSubmitForm={SubmitFormHandler} onBadInput={BadInputHandler}/>
      <UserListDisplay users={userList} />
    </div>
  );
}

export default App;
